import {
  makeStyles,
  Modal,
  Box,
  Typography,
  TableBody,
  TextField,
  Table,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import * as Yup from "yup";
import ModalButtons from "../Common/ModalButtons";
import CloseIcon from "@material-ui/icons/Close";
import { LightGreyRow } from "../Common";
import SalePricesLabelRow from "../DealsDetail/SalePricesLabelRow";
import SalesPricesDataRow from "../DealsDetail/SalesPricesDataRow";
import { DateTime } from "luxon";
import { joiDateFormat } from "../../constants";
import { strings, utils } from "common";
import { buildPreAdviceFileName, useCasesPreadvice } from "../../utils/cases";
import { useWarehouses } from "../../utils/warehouses";
import { useSuppliersList } from "../../utils/suppliers";
import { Formik, FormikProps, FormikErrors } from "formik";
import { CaseDataSchema, Warehouse } from "../../interfaces/case.interface";
import { COUNTRY_LIST } from "../../constants";
import React from "react";
import { SupplierSchema } from "../../interfaces/user.interface";

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(8),
  },
  selectElement: {
    marginTop: "8px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader: {
    width: "100%",
    paddingBottom: theme.spacing(7),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContent: {
    width: "800px",
    background: "white",
    outline: "none",
    padding: theme.spacing(12, 12, 12, 12),
    maxHeight: "90%",
    overflow: "hidden",
    overflowY: "scroll",
  },
  input: {
    marginTop: "8px",
    marginBottom: "4px",
  },
  closeIcon: {
    cursor: "pointer",
  },
  tableContent: {
    maxHeight: "300px",
    overflow: "hidden",
    overflowY: "scroll",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
}));

const deliveryTypesData = ["PUR", "RWC"];

// this will come from a vintrade endpoint
const addressData = {
  supplierAccountCode: "",
  supplierContactName: "",
  supplierAddressLine1: "",
  supplierAddressLine2: "",
  supplierAddressLine3: "",
  supplierTown: "",
  supplierPostCode: "",
};

interface CreatePreAdviceModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  selectedrows: Array<CaseDataSchema>;
  deSelectAll: () => void;
  setErrorModal?: (val: string | null) => void;
  totalCWPurchasePrice?: number;
}

interface ValuesModal {
  warehouse: Warehouse;
  expectedDeliveryDate: string | null;
  deliveryType: string;
  supplierAccountCode: string;
  supplierContactName: string;
  supplierAddressLine1: string;
  supplierAddressLine2: string;
  supplierAddressLine3: string;
  supplierTown: string;
  supplierPostCode: string;
  supplierCountry: string;
  linkStock: boolean;
}

const CreatePreAdviceModal = ({
  open,
  setOpen,
  selectedrows,
  deSelectAll,
  setErrorModal,
  totalCWPurchasePrice,
}: CreatePreAdviceModalProps) => {
  const formRef = React.useRef<FormikProps<ValuesModal> | null>();
  const classes = useStyles();
  const [supplierInput, setSupplierInput] = React.useState("");
  const { data: warehousesData } = useWarehouses(true);
  const { data: supplierData } = useSuppliersList(supplierInput);
  const { data: prefilledSupplierData } = useSuppliersList(selectedrows[0]?.supplierName || "");
  const { mutateAsync: postCasesPreadvice, isLoading } = useCasesPreadvice();

  const downloadXmlContent = (fileName: string, xmlContent: string) => {
    // TODO: remove, when Vision EDI testing moves from sending emails to sending API calls
    const element = document.createElement("a");
    const file = new Blob([xmlContent], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
  };

  const casesToShip = selectedrows;
  const defaultWarehouse: Warehouse =
    warehousesData && warehousesData[0]
      ? {
          id: warehousesData[0].id,
          warehouseVisionCode: warehousesData[0].warehouseVisionCode,
          warehouseShortCode: warehousesData[0].warehouseShortCode,
        }
      : {
          id: -1,
          warehouseVisionCode: "",
          warehouseShortCode: "",
        };

  const initValues: ValuesModal = {
    warehouse: selectedrows[0] ? selectedrows[0].warehouse : defaultWarehouse,
    deliveryType: deliveryTypesData[0] ?? "",
    supplierAccountCode: addressData.supplierAccountCode,
    supplierContactName: addressData.supplierContactName,
    supplierAddressLine1: addressData.supplierAddressLine1,
    supplierAddressLine2: addressData.supplierAddressLine2,
    supplierAddressLine3: addressData.supplierAddressLine3,
    supplierTown: addressData.supplierTown,
    supplierPostCode: addressData.supplierPostCode,
    expectedDeliveryDate: null,
    supplierCountry: "GB",
    linkStock: true,
  };

  React.useEffect(() => {
    if (prefilledSupplierData) {
      if (prefilledSupplierData.data[0] && formRef.current) {
        fillSupplierForm(prefilledSupplierData.data[0], formRef.current.setFieldValue, formRef.current?.validateForm);
      }
    }
  }, [prefilledSupplierData]);

  function fillSupplierForm(
    supplier: SupplierSchema,
    setter: (field: string, value: string, shouldValidate?: boolean | undefined) => void,
    validateForm: (values?: unknown) => Promise<FormikErrors<ValuesModal>>
  ) {
    const { addressLine1: addressLine, postCode, country, name, customerId, town } = supplier;
    const breakedAddress = addressLine.split(",");
    const [addressLine1, addressLine2, addressLine3] = breakedAddress;
    setter("supplierContactName", name);
    setter("supplierAccountCode", customerId.toString());
    setter("supplierAddressLine1", addressLine1 || "");
    setter("supplierAddressLine2", addressLine2 || "");
    setter("supplierAddressLine3", addressLine3 || "");
    setter("supplierPostCode", postCode);
    setter("supplierCountry", COUNTRY_LIST.find((e) => e.name === country)?.value || "");
    setter("supplierTown", town || "");
    setTimeout(() => {
      validateForm();
    }, 0);
  }

  function resetSupplierForm(setter: (field: string, value: string, shouldValidate?: boolean | undefined) => void) {
    setter("supplierContactName", initValues.supplierContactName);
    setter("supplierAccountCode", initValues.supplierAccountCode);
    setter("supplierAddressLine1", initValues.supplierAddressLine1);
    setter("supplierAddressLine2", initValues.supplierAddressLine2);
    setter("supplierAddressLine3", initValues.supplierAddressLine3);
    setter("supplierPostCode", initValues.supplierPostCode);
    setter("supplierCountry", initValues.supplierCountry);
    setter("supplierTown", initValues.supplierTown);
  }

  return (
    <Formik
      innerRef={(f) => (formRef.current = f)}
      initialValues={initValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        warehouse: Yup.object().required(),
        deliveryType: Yup.string(),
        expectedDeliveryDate: Yup.string().required(),
        supplierAccountCode: Yup.string().required(),
        supplierContactName: Yup.string().required(),
        supplierAddressLine1: Yup.string().required(),
        supplierAddressLine2: Yup.string(),
        supplierAddressLine3: Yup.string(),
        supplierTown: Yup.string().required(),
        supplierPostCode: Yup.string().required(),
        supplierCountry: Yup.string().required(),
        linkStock: Yup.boolean().required(),
      })}
      onSubmit={async (values: ValuesModal) => {
        try {
          const selectedWineIds = selectedrows.map((caseRow) => caseRow.id);
          const result = await postCasesPreadvice({
            ids: selectedWineIds,
            deliveryWarehouseId: values.warehouse?.id,
            expectedDeliveryDate: values.expectedDeliveryDate,
            deliveryType: values.deliveryType,
            supplierAccountCode: values.supplierAccountCode,
            supplierContactName: values.supplierContactName,
            supplierAddressLine1: values.supplierAddressLine1,
            supplierAddressLine2: values.supplierAddressLine2,
            supplierAddressLine3: values.supplierAddressLine3,
            supplierTown: values.supplierTown,
            supplierPostCode: values.supplierPostCode,
            customerCode: values.warehouse.warehouseVisionCode,
            siteCode: values.warehouse.warehouseShortCode,
            supplierCountry: values.supplierCountry,
            linkStock: values.linkStock,
          });
          // TODO: remove, when Vision EDI testing moves from sending emails to sending API calls

          result.xmlPayloadList.forEach((item) => {
            const fileName = buildPreAdviceFileName(item.purchaseOrderNumber, values.warehouse.warehouseVisionCode);
            downloadXmlContent(fileName, item.xmlPayload);
          });
          setOpen(false);
          deSelectAll();
        } catch (error) {
          if (setErrorModal) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setErrorModal(error.description);
          }
        }
      }}
    >
      {({ values, handleChange, setFieldValue, handleSubmit, errors, validateForm }) => (
        <Modal id="createPreAdviceModal" className={classes.modal} open={open}>
          <Box className={classes.modalContent}>
            <Box className={classes.modalHeader}>
              <Typography variant="h3">{strings.create_pre_advice}</Typography>
              <CloseIcon id="closePAModal" className={classes.closeIcon} onClick={() => setOpen(false)} />
            </Box>

            <Box className={classes.tableContent}>
              <Table stickyHeader aria-label="table">
                <SalePricesLabelRow />
                <TableBody>
                  {casesToShip.map((row) => (
                    <LightGreyRow key={row.id}>
                      <SalesPricesDataRow data={row} />
                    </LightGreyRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            {totalCWPurchasePrice && (
              <Box display="flex" className={classes.summaryContainer}>
                <Typography variant="body2">
                  {strings.sumSelected} {selectedrows.length}
                </Typography>
                <Typography variant="body2">
                  {strings.CWPurchasePrice} £{totalCWPurchasePrice}
                </Typography>
              </Box>
            )}

            <Box>
              <TextField
                type="date"
                label={strings.expected_delivery_date}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.input}
                fullWidth
                onChange={(e) =>
                  setFieldValue(
                    "expectedDeliveryDate",
                    utils.date.isValidDate(e.target.value)
                      ? DateTime.fromJSDate(new Date(e.target.value)).toFormat(joiDateFormat)
                      : null
                  )
                }
                error={Boolean(errors?.expectedDeliveryDate)}
              />

              <FormControl fullWidth>
                <InputLabel>{strings.warehouse}</InputLabel>
                <Select
                  value={values.warehouse}
                  className={classes.input}
                  name="warehouse"
                  onChange={(e) => setFieldValue("warehouse", e.target.value)}
                  error={Boolean(errors?.warehouse)}
                >
                  {warehousesData &&
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    warehousesData.map((currentWarehouse: any) => (
                      <MenuItem value={currentWarehouse}>{currentWarehouse.warehouseName}</MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Box m={4} p={4} bgcolor="#FAFAFA" borderRadius="16px">
                <Grid spacing={0} container>
                  <Grid item xs={6}>
                    <TextField
                      id="customerCode"
                      label="Customer Code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      name="customerCode"
                      value={values.warehouse?.warehouseVisionCode}
                      error={Boolean(errors?.warehouse)}
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="siteCode"
                      label="Site Code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      error={Boolean(errors?.warehouse)}
                      value={values.warehouse?.warehouseShortCode}
                      className={classes.input}
                    />
                  </Grid>
                </Grid>
              </Box>

              <FormControl fullWidth>
                <InputLabel>{strings.delivery_type}</InputLabel>
                <Select
                  value={values.deliveryType}
                  className={classes.input}
                  name="deliveryType"
                  onChange={(e) => setFieldValue("deliveryType", e.target.value)}
                  error={Boolean(errors?.deliveryType)}
                >
                  {deliveryTypesData.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              {!prefilledSupplierData?.data.length && (
                <FormControl fullWidth>
                  <Autocomplete
                    options={supplierData?.data.sort((a, b) => a.name.localeCompare(b.name)) || []}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label={strings.select_supplier} />
                    )}
                    getOptionLabel={(option) => option.name}
                    style={{ marginTop: 10, marginBottom: 10 }}
                    onInputChange={(_, value) => {
                      setSupplierInput(value);
                    }}
                    onChange={(_, option) => {
                      resetSupplierForm(setFieldValue);
                      const selectedSupplier = supplierData?.data.find(
                        (supplier) => supplier.userId === option?.userId
                      );
                      if (selectedSupplier) {
                        fillSupplierForm(selectedSupplier, setFieldValue, validateForm);
                      }
                    }}
                  />
                </FormControl>
              )}

              <Grid spacing={4} container>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="supplierAccountCode"
                      label="Supplier Account Code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name="supplierAccountCode"
                      value={values.supplierAccountCode}
                      error={Boolean(errors?.supplierAccountCode)}
                      className={classes.input}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="supplierContactName"
                      label="Supplier Account Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name="supplierContactName"
                      value={values.supplierContactName}
                      error={Boolean(errors?.supplierContactName)}
                      className={classes.input}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid spacing={4} container>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="supplierAddressLine1"
                      label="Supplier Address Line 1"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name="supplierAddressLine1"
                      value={values.supplierAddressLine1}
                      error={Boolean(errors?.supplierAddressLine1)}
                      className={classes.input}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="supplierAddressLine2"
                      label="Supplier Address Line 2"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name="supplierAddressLine2"
                      value={values.supplierAddressLine2}
                      error={Boolean(errors?.supplierAddressLine2)}
                      className={classes.input}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid spacing={4} container>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="supplierAddressLine3"
                      label="Supplier Address Line 3"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name="supplierAddressLine3"
                      onChange={handleChange}
                      value={values.supplierAddressLine3}
                      error={Boolean(errors?.supplierAddressLine3)}
                      className={classes.input}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="supplierTown"
                      label="Supplier Town"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name="supplierTown"
                      value={values.supplierTown}
                      error={Boolean(errors?.supplierTown)}
                      className={classes.input}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid spacing={4} container>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="supplierPostCode"
                      label="Supplier Post Code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name="supplierPostCode"
                      value={values.supplierPostCode}
                      error={Boolean(errors?.supplierPostCode)}
                      className={classes.input}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} className={classes.selectElement}>
                  <FormControl fullWidth>
                    <InputLabel>{strings.country}</InputLabel>
                    <Select
                      value={values.supplierCountry}
                      className={classes.input}
                      name="supplierCountry"
                      onChange={(e) => setFieldValue("supplierCountry", e.target.value)}
                      error={Boolean(errors?.supplierCountry)}
                    >
                      {COUNTRY_LIST.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                        <MenuItem value={item.value}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.row} fullWidth>
                    <Switch
                      checked={values.linkStock}
                      onChange={(e) => {
                        setFieldValue("linkStock", e.target.checked);
                      }}
                    />
                    <Typography variant="body2">{strings.allocate}</Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <ModalButtons
              submitId="submitPAModal"
              cancelId="cancelPAModal"
              closeModal={() => setOpen(false)}
              onClick={handleSubmit}
              isSubmitting={isLoading}
            />
          </Box>
        </Modal>
      )}
    </Formik>
  );
};

CreatePreAdviceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedrows: PropTypes.array,
};

export default CreatePreAdviceModal;

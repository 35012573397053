// // import Dashboard from "@material-ui/icons/Dashboard";
// import LocalOffer from "@material-ui/icons/LocalOffer";
import Apps from "@material-ui/icons/Apps";
import Receipt from "@material-ui/icons/Receipt";
import Announcement from "@material-ui/icons/Announcement";
import GroupIcon from "@material-ui/icons/Group";
// import LinkIcon from "@material-ui/icons/Link";
import { strings } from "common";

export const boolToString = {
  true: strings.yes,
  false: strings.no,
};

export const menuOptions = {
  // Dashboard: {
  //   icon: Dashboard,
  //   route: "/",
  //   title: strings.dashboard,
  // },
  Stock: {
    icon: Apps,
    route: "/stock",
    title: strings.stock,
  },
  Orders: {
    icon: Receipt,
    route: "/orders",
    title: strings.orders,
  },
  // Deals: {
  //   icon: LocalOffer,
  //   route: "/deals",
  //   title: strings.deals,
  // },
  Tickets: {
    icon: Announcement,
    route: "/tickets",
    title: strings.tickets,
  },
  Users: {
    icon: GroupIcon,
    route: "/users",
    title: strings.users,
  },
  // Assignment: {
  //   icon: LinkIcon,
  //   route: "/assignments",
  //   title: strings.assignments,
  // },
};

export const httpCodes = {
  OK: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER: 500,
};

export const validReleaseTypes = [
  "GB Duty Paid",
  "Export (non EC)",
  "GB Warehouse Underbond",
  "EC Export",
  "Diplomatic",
  "Transfer to another warehouse customer",
  "Visiting forces/NATO",
  "NI Duty Paid",
  "NI Warehouse Underbond",
];

export const rolesList = {
  OPERATIONS: "OPERATIONS",
  WAREHOUSING: "WAREHOUSING",
  ADMIN_WAREHOUSING: "ADMIN_WAREHOUSING",
  ADMIN: "ADMIN",
};

export const casesStatusList = {
  IN_TRANSIT: "IN_TRANSIT",
  IN_STOCK: "IN_STOCK",
  CREATED: "CREATED",
  SHORT: "SHORT",
  PENDING_TRANSPORT: "PENDING_TRANSPORT",
  PENDING_RELEASE: "PENDING_RELEASE",
  RELEASE_PROCESSED: "RELEASE_PROCESSED",
  DEPARTED: "DEPARTED",
  TICKETED: "TICKETED",
  INTERIM: "INTERIM",
  CANCELLED: "CANCELLED",
  CANCELLED_PENDING: "CANCELLED_PENDING",
  PENDING_MIGRATION: "PENDING_MIGRATION",
  MIGRATING: "MIGRATING",
  EXTERNALLY_MANAGED: "EXTERNALLY_MANAGED",
  PENDING_EXTERNAL: "PENDING_EXTERNAL",
};

export const caseStatus = {
  [casesStatusList.IN_TRANSIT]: strings.in_transit,
  [casesStatusList.IN_STOCK]: strings.in_stock,
  [casesStatusList.CREATED]: strings.create,
  [casesStatusList.SHORT]: strings.short,
  [casesStatusList.PENDING_RELEASE]: strings.pending_release,
  [casesStatusList.DEPARTED]: strings.departed,
  [casesStatusList.CANCELLED]: strings.cancelled,
  [casesStatusList.CANCELLED_PENDING]: strings.cancelled_pending,
  [casesStatusList.PENDING_MIGRATION]: strings.pending_migration,
  [casesStatusList.MIGRATING]: strings.migrating,
  [casesStatusList.EXTERNALLY_MANAGED]: strings.externally_managed,
  [casesStatusList.PENDING_EXTERNAL]: strings.pending_external,
};

export const orderDateFormat = "dd/MM/yyyy";
export const dateFormat = "dd-LL-yyyy";
export const datetimeFormat = `${dateFormat}, HH:mm`;
export const joiDateFormat = "yyyy-LL-dd";

export const rowsPerPageOptions = [10, 20, 30, 50, 100, 500, 1000, 1500];

export const roles = {
  OPERATIONS: strings.Operations,
  WAREHOUSING: strings.warehousing,
  ADMIN_WAREHOUSING: strings.admin_warehousing,
  ADMIN: strings.admin,
};

export const dealsStatusList = {
  IN_PROGRESS: "IN_PROGRESS",
  CLOSED: "CLOSED",
};

export const boolToStringForRelation = {
  true: strings.linked,
  false: strings.orphanedAssetRecord,
};

export const dealTypeList = {
  RELEASE: "RELEASE",
  TRADE: "TRADE",
};

export const salesType = {
  [dealTypeList.RELEASE]: strings.release,
  [dealTypeList.TRADE]: strings.trade,
};

export const issueType = {
  QUERY: strings.query,
  DISCREPANCY: strings.discrepancy,
  MIGRATION_ISSUE: strings.migrationIssue,
};

export const issueStatusList = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  PENDING: "PENDING",
};

export const issueStatus = {
  [issueStatusList.OPEN]: strings.open,
  [issueStatusList.CLOSED]: strings.closed,
  [issueStatusList.PENDING]: strings.pending,
};

export const preadviceStatusList = {
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESSFUL: "SUCCESSFUL",
  UNSUCCESSFUL: "UNSUCCESSFUL",
  CANCELLED: "CANCELLED",
};

export const preadviceStatuses = {
  [preadviceStatusList.IN_PROGRESS]: strings.in_progress,
  [preadviceStatusList.SUCCESSFUL]: strings.successful,
  [preadviceStatusList.UNSUCCESSFUL]: strings.unsuccessful,
};

export const SalesOrderStatusList = {
  CREATED: "CREATED",
  SENT: "SENT",
  SEND_FAILED: "SEND_FAILED",
};

export const SalesOrderStatuses = {
  [SalesOrderStatusList.CREATED]: strings.create,
  [SalesOrderStatusList.SENT]: strings.sent,
  [SalesOrderStatusList.SEND_FAILED]: strings.send_failed,
};

export const entityTypesList = {
  CASES: "CASES",
  ISSUES: "ISSUES",
  ISSUE_COMMENTS: "ISSUE_COMMENTS",
  DEALS: "DEALS",
  SALES_ORDER: "SALES_ORDER",
  PREADVICES: "PREADVICES",
};

export const entityTypes = {
  [entityTypesList.CASES]: strings.cases,
  [entityTypesList.ISSUES]: strings.issues,
  [entityTypesList.ISSUE_COMMENTS]: strings.issue_comments,
  [entityTypesList.DEALS]: strings.deals,
  [entityTypesList.PREADVICES]: strings.pre_advice,
  [entityTypesList.SALES_ORDER]: strings.sales_order,
};

export const actionTypesList = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const actionTypes = {
  [actionTypesList.CREATE]: strings.create,
  [actionTypesList.UPDATE]: strings.update,
  [actionTypesList.DELETE]: strings.deleted,
};

export const dealsStatus = {
  IN_PROGRESS: strings.in_progress,
  CLOSED: strings.closed,
};

export const filtersToParams = {
  ...issueType,
  ...issueStatus,
  ...caseStatus,
  ...dealsStatus,
  ...preadviceStatuses,
  ...salesType,
  ...entityTypes,
  ...actionTypes,
  ...SalesOrderStatuses,
};

export const COUNTRY_LIST = [
  { name: "Andorra", value: "AD" },
  { name: "United Arab Emirates", value: "AE" },
  { name: "Afghanistan	34 provinces", value: "AF" },
  { name: "Antigua and Barbuda", value: "AG" },
  { name: "Anguilla", value: "AI" },
  { name: "Albania", value: "AL" },
  { name: "Armenia", value: "AM" },
  { name: "Angola", value: "AO" },
  { name: "Antarctica", value: "AQ" },
  { name: "Argentina", value: "AR" },
  { name: "American Samoa", value: "AS" },
  { name: "Austria", value: "AT" },
  { name: "Australia", value: "AU" },
  { name: "Aruba", value: "AW" },
  { name: "Åland Islands", value: "AX" },
  { name: "Azerbaijan", value: "AZ" },
  { name: "Bosnia and Herzegovina", value: "BA" },
  { name: "Barbados", value: "BB" },
  { name: "Bangladesh", value: "BD" },
  { name: "Belgium", value: "BE" },
  { name: "Burkina Faso", value: "BF" },
  { name: "Bulgaria", value: "BG" },
  { name: "Bahrain", value: "BH" },
  { name: "Burundi", value: "BI" },
  { name: "Benin", value: "BJ" },
  { name: "Saint Barthélemy", value: "BL" },
  { name: "Bermuda", value: "BM" },
  { name: "Brunei Darussalam", value: "BN" },
  { name: "Bolivia (Plurinational State of)", value: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
  { name: "Brazil", value: "BR" },
  { name: "Bahamas", value: "BS" },
  { name: "Bhutan", value: "BT" },
  { name: "Bouvet Island", value: "BV" },
  { name: "Botswana", value: "BW" },
  { name: "Belarus", value: "BY" },
  { name: "Belize", value: "BZ" },
  { name: "Canada", value: "CA" },
  { name: "Cocos (Keeling) Islands", value: "CC" },
  { name: "Congo, Democratic Republic of the", value: "CD" },
  { name: "Central African Republic", value: "CF" },
  { name: "Congo", value: "CG" },
  { name: "Switzerland", value: "CH" },
  { name: "Côte d'Ivoire", value: "CI" },
  { name: "Cook Islands", value: "CK" },
  { name: "Chile", value: "CL" },
  { name: "Cameroon", value: "CM" },
  { name: "China", value: "CN" },
  { name: "Colombia", value: "CO" },
  { name: "Costa Rica", value: "CR" },
  { name: "Cuba", value: "CU" },
  { name: "Cabo Verde", value: "CV" },
  { name: "Curaçao", value: "CW" },
  { name: "Christmas Island", value: "CX" },
  { name: "Cyprus", value: "CY" },
  { name: "Czechia", value: "CZ" },
  { name: "Germany", value: "DE" },
  { name: "Djibouti", value: "DJ" },
  { name: "Denmark", value: "DK" },
  { name: "Dominica", value: "DM" },
  { name: "Dominican Republic", value: "DO" },
  { name: "Algeria", value: "DZ" },
  { name: "Ecuador", value: "EC" },
  { name: "Estonia", value: "EE" },
  { name: "Egypt", value: "EG" },
  { name: "Western Sahara", value: "EH" },
  { name: "Eritrea", value: "ER" },
  { name: "Spain", value: "ES" },
  { name: "Ethiopia", value: "ET" },
  { name: "Finland", value: "FI" },
  { name: "Fiji", value: "FJ" },
  { name: "Falkland Islands (Malvinas)", value: "FK" },
  { name: "Micronesia (Federated States of)", value: "FM" },
  { name: "Faroe Islands", value: "FO" },
  { name: "France", value: "FR" },
  { name: "Gabon", value: "GA" },
  { name: "United Kingdom", value: "GB" },
  { name: "Grenada", value: "GD" },
  { name: "Georgia", value: "GE" },
  { name: "French Guiana", value: "GF" },
  { name: "Guernsey", value: "GG" },
  { name: "Ghana", value: "GH" },
  { name: "Gibraltar", value: "GI" },
  { name: "Greenland", value: "GL" },
  { name: "Gambia", value: "GM" },
  { name: "Guinea", value: "GN" },
  { name: "Guadeloupe", value: "GP" },
  { name: "Equatorial Guinea", value: "GQ" },
  { name: "Greece", value: "GR" },
  { name: "South Georgia and the South Sandwich Islands", value: "GS" },
  { name: "Guatemala", value: "GT" },
  { name: "Guam", value: "GU" },
  { name: "Guinea-Bissau", value: "GW" },
  { name: "Guyana", value: "GY" },
  { name: "Hong Kong", value: "HK" },
  { name: "Heard Island and McDonald Islands", value: "HM" },
  { name: "Honduras", value: "HN" },
  { name: "Croatia", value: "HR" },
  { name: "Haiti", value: "HT" },
  { name: "Hungary", value: "HU" },
  { name: "Indonesia", value: "ID" },
  { name: "Ireland", value: "IE" },
  { name: "Israel", value: "IL" },
  { name: "Isle of Man", value: "IM" },
  { name: "India", value: "IN" },
  { name: "British Indian Ocean Territory", value: "IO" },
  { name: "Iraq", value: "IQ" },
  { name: "Iran (Islamic Republic of)", value: "IR" },
  { name: "Iceland", value: "IS" },
  { name: "Italy", value: "IT" },
  { name: "Jersey", value: "JE" },
  { name: "Jamaica", value: "JM" },
  { name: "Jordan", value: "JO" },
  { name: "Japan", value: "JP" },
  { name: "Kenya", value: "KE" },
  { name: "Kyrgyzstan", value: "KG" },
  { name: "Cambodia", value: "KH" },
  { name: "Kiribati", value: "KI" },
  { name: "Comoros", value: "KM" },
  { name: "Saint Kitts and Nevis", value: "KN" },
  { name: "Korea (Democratic People's Republic of)", value: "KP" },
  { name: "Korea, Republic of", value: "KR" },
  { name: "Kuwait", value: "KW" },
  { name: "Cayman Islands", value: "KY" },
  { name: "Kazakhstan", value: "KZ" },
  { name: "Lao People's Democratic Republic", value: "LA" },
  { name: "Lebanon", value: "LB" },
  { name: "Saint Lucia", value: "LC" },
  { name: "Liechtenstein", value: "LI" },
  { name: "Sri Lanka", value: "LK" },
  { name: "Liberia", value: "LR" },
  { name: "Lesotho", value: "LS" },
  { name: "Lithuania", value: "LT" },
  { name: "Luxembourg", value: "LU" },
  { name: "Latvia", value: "LV" },
  { name: "Libya", value: "LY" },
  { name: "Morocco", value: "MA" },
  { name: "Monaco", value: "MC" },
  { name: "Moldova, Republic of", value: "MD" },
  { name: "Montenegro", value: "ME" },
  { name: "Saint Martin (French part)", value: "MF" },
  { name: "Madagascar", value: "MG" },
  { name: "Marshall Islands", value: "MH" },
  { name: "North Macedonia", value: "MK" },
  { name: "Mali", value: "ML" },
  { name: "Myanmar", value: "MM" },
  { name: "Mongolia", value: "MN" },
  { name: "Macao", value: "MO" },
  { name: "Northern Mariana Islands", value: "MP" },
  { name: "Martinique", value: "MQ" },
  { name: "Mauritania", value: "MR" },
  { name: "Montserrat", value: "MS" },
  { name: "Malta", value: "MT" },
  { name: "Mauritius", value: "MU" },
  { name: "Maldives", value: "MV" },
  { name: "Malawi", value: "MW" },
  { name: "Mexico", value: "MX" },
  { name: "Malaysia", value: "MY" },
  { name: "Mozambique", value: "MZ" },
  { name: "Namibia", value: "NA" },
  { name: "New Caledonia", value: "NC" },
  { name: "Niger", value: "NE" },
  { name: "Norfolk Island", value: "NF" },
  { name: "Nigeria", value: "NG" },
  { name: "Nicaragua", value: "NI" },
  { name: "Netherlands", value: "NL" },
  { name: "Norway", value: "NO" },
  { name: "Nepal", value: "NP" },
  { name: "Nauru", value: "NR" },
  { name: "Niue", value: "NU" },
  { name: "New Zealand", value: "NZ" },
  { name: "Oman", value: "OM" },
  { name: "Panama", value: "PA" },
  { name: "Peru", value: "PE" },
  { name: "French Polynesia", value: "PF" },
  { name: "Papua New Guinea", value: "PG" },
  { name: "Philippines", value: "PH" },
  { name: "Pakistan", value: "PK" },
  { name: "Poland", value: "PL" },
  { name: "Saint Pierre and Miquelon", value: "PM" },
  { name: "Pitcairn", value: "PN" },
  { name: "Puerto Rico", value: "PR" },
  { name: "Palestine, State of", value: "PS" },
  { name: "Portugal", value: "PT" },
  { name: "Palau", value: "PW" },
  { name: "Paraguay", value: "PY" },
  { name: "Qatar", value: "QA" },
  { name: "Réunion", value: "RE" },
  { name: "Romania", value: "RO" },
  { name: "Serbia", value: "RS" },
  { name: "Russian Federation", value: "RU" },
  { name: "Rwanda", value: "RW" },
  { name: "Saudi Arabia", value: "SA" },
  { name: "Solomon Islands", value: "SB" },
  { name: "Seychelles", value: "SC" },
  { name: "Sudan", value: "SD" },
  { name: "Sweden", value: "SE" },
  { name: "Singapore", value: "SG" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
  { name: "Slovenia", value: "SI" },
  { name: "Svalbard and Jan Mayen", value: "SJ" },
  { name: "Slovakia", value: "SK" },
  { name: "Sierra Leone", value: "SL" },
  { name: "San Marino", value: "SM" },
  { name: "Senegal", value: "SN" },
  { name: "Somalia", value: "SO" },
  { name: "Suriname", value: "SR" },
  { name: "South Sudan", value: "SS" },
  { name: "Sao Tome and Princip", value: "ST" },
  { name: "El Salvador", value: "SV" },
  { name: "Sint Maarten (Dutch part)", value: "SX" },
  { name: "Syrian Arab Republic", value: "SY" },
  { name: "Eswatini", value: "SZ" },
  { name: "Turks and Caicos Islands", value: "TC" },
  { name: "Chad", value: "TD" },
  { name: "French Southern Territories", value: "TF" },
  { name: "Togo", value: "TG" },
  { name: "Thailand", value: "TH" },
  { name: "Tajikistan", value: "TJ" },
  { name: "Tokelau", value: "TK" },
  { name: "Timor-Leste", value: "TL" },
  { name: "Turkmenistan", value: "TM" },
  { name: "Tunisia", value: "TN" },
  { name: "Tonga", value: "TO" },
  { name: "Türkiye", value: "TR" },
  { name: "Trinidad and Tobago", value: "TT" },
  { name: "Tuvalu", value: "TV" },
  { name: "Taiwan, Province of China", value: "TW" },
  { name: "Tanzania, United Republic of", value: "TZ" },
  { name: "Ukraine", value: "UA" },
  { name: "Uganda", value: "UG" },
  { name: "United States Minor Outlying Islands", value: "UM" },
  { name: "United States of America", value: "US" },
  { name: "Uruguay", value: "UY" },
  { name: "Uzbekistan", value: "UZ" },
  { name: "Holy See", value: "VA" },
  { name: "Saint Vincent and the Grenadines", value: "VC" },
  { name: "Venezuela (Bolivarian Republic of)", value: "VE" },
  { name: "Virgin Islands (British)", value: "VG" },
  { name: "Virgin Islands (U.S.)", value: "VI" },
  { name: "Viet Nam", value: "VN" },
  { name: "Vanuatu", value: "VU" },
  { name: "Wallis and Futuna", value: "WF" },
  { name: "Samoa", value: "WS" },
  { name: "Yemen", value: "YE" },
  { name: "Mayotte", value: "YT" },
  { name: "South Africa", value: "ZA" },
  { name: "Zambia", value: "ZM" },
  { name: "Zimbabwe", value: "ZW" },
];
